import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { ChevronDownIcon, CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Badge from './Badge';

// eslint-disable-next-line max-lines-per-function
function MultipleCombobox(props) {
  const {
    label = '',
    name = '',
    defaultValue = [],
    data = [],
    displayValue = () => {},
    displayOptions = () => {},
    filter = () => true,
    // color = 'blue',
    className = '',
    classNameInput = '',
    classNameLabel = '',
    classNameOptions = '',
    valueKey = (data) => data?.id,
    icon: Icon = MagnifyingGlassIcon,
    value: valueControlled = '',
    setValue: setValueControlled = () => {},
    isControlled = false,
    required = false,
    ...rest
  } = props;

  const [query, setQuery] = useState('');
  const [selectedInternal, setSelectedInternal] = useState(defaultValue);
  const [selectedLi, setSelectedLi] = useState(-1);
  const [paddingText, setPaddingText] = useState(0);

  const selectedContainer = useRef(null);

  const filteredData = query === '' ? data : data.filter((o) => filter(o, query));

  // Switch between local or external state
  const selected = isControlled ? valueControlled : selectedInternal;
  const setSelected = isControlled ? setValueControlled : setSelectedInternal;

  // Handle keydowns event (ArrownDown 40, ArrowUp 38 , Enter 13, Space 32, Backspace 8)
  const switchOption = (e) => {
    if (
      [40, 38].includes(e.keyCode) ||
      ([13, 32].includes(e.keyCode) && filteredData?.[selectedLi])
    )
      e.preventDefault();
    if (e.keyCode === 40) setSelectedLi((prev) => Math.min(prev + 1, filteredData.length - 1));
    else if (e.keyCode === 38) setSelectedLi((prev) => Math.max(prev - 1, -1));
    else if ([13, 32].includes(e.keyCode) && filteredData?.[selectedLi])
      selectData(filteredData?.[selectedLi]);
    else if (e.keyCode === 8 && query.length === 0) removeData(selected.length - 1);
  };

  // Handle the multiple selection
  const selectData = (o) => {
    if (selected?.some((s) => valueKey(s) === valueKey(o))) {
      const indexRemoval = selected?.findIndex((s) => valueKey(s) === valueKey(o));
      removeData(indexRemoval);
    } else setSelected((prev) => [...prev, o]);

    // Empty the query for the next research
    setQuery('');
  };

  // Remove value
  const removeData = (index) =>
    setSelected((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);

  // Update Layout Synchronously (otherwise graphical on the first update)
  useLayoutEffect(() => {
    setPaddingText(selectedContainer.current.offsetWidth);
  });

  // Update when the defaultValue is updated (after loading for example)
  useEffect(() => {
    setSelectedInternal(defaultValue);
  }, [defaultValue]);

  return (
    <div className={className}>
      <label htmlFor={name} className={'block text-sm font-medium text-gray-700 ' + classNameLabel}>
        {label}
      </label>
      {!isControlled && selected.length === 0 && (
        <input
          className="opacity-0 absolute inset-x-1/2 w-1/4"
          name={name}
          required={required}
          value={null}
        />
      )}
      {!isControlled &&
        selected?.map((s, index) => (
          <input
            key={`hidden_input_${index}`}
            className="opacity-0 absolute inset-x-1/2 w-1/4"
            name={name}
            value={valueKey(s)}
            required={required}
          />
        ))}
      <div className="relative">
        <div
          ref={selectedContainer}
          className={'absolute inset-y-0 flex items-center ' + (Icon ? 'pl-10 ' : 'pl-1')}>
          {selected?.map((o, index) => (
            <Badge className="mr-2" key={`badge_${index}`} onClick={() => removeData(index)}>
              {displayValue(o)}
            </Badge>
          ))}
        </div>
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-1/2 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pointer-events-none focus:outline-none">
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          value={query}
          autoComplete="off"
          onKeyDown={(e) => switchOption(e)}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setSelectedLi(-1)}
          style={{ paddingLeft: paddingText }}
          className={`peer mt-1 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md ${classNameInput}`}
          id={name}
          {...rest}
        />

        <ul
          id={`list_${name}`}
          tabIndex={0}
          className={
            'absolute top-11 left-0 z-10 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm invisible peer-focus:visible hover:visible focus:visible'
          }>
          {filteredData?.map((o, index) => (
            <li
              key={`option_${index}`}
              onClick={() => selectData(o)}
              className={
                'group relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-blue-600 hover:text-white ' +
                (selectedLi === index ? ' bg-blue-600 text-white ' : 'text-gray-900 ') +
                classNameOptions
              }>
              {displayOptions(o)}
              {selected?.map((s) => valueKey(s)).includes(valueKey(o)) && (
                <span
                  className={
                    'absolute inset-y-0 left-0 flex items-center pl-3  group-hover:text-white ' +
                    (selectedLi === index ? ' text-white ' : ' text-blue-600 ')
                  }>
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MultipleCombobox;
