function InputText(props) {
  const {
    label,
    className = '',
    classNameInput = '',
    name = '',
    ...rest // To handle all unhandled props so far
  } = props;

  return (
    <div className={'relative ' + className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        type="text"
        className={`mt-1 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md ${classNameInput}`}
        name={name}
        id={name}
        {...rest}
      />
    </div>
  );
}

export default InputText;
