import { useState } from 'react';
import { Button, Modal, InputText, Alert, Form, Table } from '@components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { Navigation, SubNavigation, Section, NetworkNotification } from '@layouts';
import moment from 'moment';
import { checkPolicy } from '@pages/ContainerEdit';
import queries from '@queries';
import { status } from '@root/config';
import _ from 'lodash';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { usePersistMutation } from '@hooks';
import uuid from 'react-uuid';

function Container() {
  const navigate = useNavigate();

  const [currentContainer, setCurrentContainer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const queryClient = useQueryClient();
  const [containerToDuplicate, setContainerToDuplicate] = useState({});

  useQuery(['articles'], () => queries.getAll('/api/articles'));

  const createMedicinesDuplicateMutation = usePersistMutation(
    ['containers'],
    (data) => queries.post('/api/medicines', data),
    () => setOpenDuplicateModal(false),
    () => ({
      ...containerToDuplicate,
      name: `${containerToDuplicate.name} (copie)`,
      id: uuid(),
      medicines: containerToDuplicate?.medicines
    }),
    false
  );

  const createContainersMutation = usePersistMutation(
    ['containers'],
    (data) => queries.post('/api/containers', data),
    () => {
      setOpenModal(false);
      setOpenDuplicateModal(false);
      setIsLoading(false);
    },
    (variables) => ({ ...variables[0], id: uuid() }),
    'isCreate'
  );

  const { isLoading: isLoadingContainer, data: dataContainers } = useQuery(['containers'], () =>
    queries.getAll('/api/containers', { populate: ['medicines', 'medicines.article'] })
  );

  const checkStatus = (item) => item?.medicines?.map((m) => checkPolicy(m))?.sort()?.[0];

  const showExpiryDates = (item) => {
    let obj = {};
    if (item.medicines) {
      obj = _.groupBy(item.medicines, (m) => {
        if (checkPolicy(m) == 0 || checkPolicy(m) == 1) {
          return m?.article?.name;
        }
      });
      delete obj[undefined];

      let arr = [];
      for (let i = 0; i < Object.keys(obj).length; i++) {
        arr.push(
          moment(
            obj[Object.keys(obj)[i]].sort(
              (a, b) => moment(a.expiryDate).unix() - moment(b.expiryDate).unix()
            )?.[0].expiryDate
          ).format('DD/MM/YYYY')
        );
      }
      return arr.join(', ');
    }
  };

  const containers = (!isLoadingContainer && dataContainers?.data) || [];

  const showMedicines = (c) => {
    return [
      ...new Set(
        c?.medicines?.map((m) => {
          if (checkPolicy(m) == 0 || checkPolicy(m) == 1) {
            return m?.article?.name;
          }
        })
      )
    ]
      .filter((e) => e !== undefined)
      .join(', ');
  };

  const duplicateContainer = async () => {
    let arrOfMedicines = [];
    for (let i = 0; i < containerToDuplicate?.medicines?.length; i++) {
      const medicineDuplicate = await createMedicinesDuplicateMutation.mutateAsync([
        {
          ...containerToDuplicate?.medicines[i],
          article: containerToDuplicate?.medicines[i].article
        }
      ]);

      arrOfMedicines.push({ ...medicineDuplicate[0]?.data?.data });
    }
    await createContainersMutation.mutateAsync([
      {
        ...containerToDuplicate,
        name: `${containerToDuplicate.name} (copie)`,
        medicines: arrOfMedicines.map((m) => m.id)
      }
    ]);

    setOpenDuplicateModal(false);
    queryClient.invalidateQueries('containers');
  };

  return (
    <div className="h-full min-h-screen flex flex-col bg-gray-100">
      <Navigation />
      <SubNavigation active="Contenants" />
      <NetworkNotification />
      <div className="space-y-6 px-4 sm:px-6 lg:px-8 py-6">
        <Section title="Rechercher un contenant">
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div parentClassName="sm:col-span-4" className="grid grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <InputText
                value={currentContainer}
                onChange={(e) => setCurrentContainer(e.target.value)}
                placeholder="Filtrez le tableau grâce à un nom de contenant ..."
              />
            </div>
            <div className="col-span-4 sm:col-span-1 flex justify-end items-end">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}>
                + Nouveau contenant
              </Button>
            </div>
          </div>
        </Section>
        <Section title="Listes des contenants">
          <Table
            className="mt-0"
            data={containers
              ?.filter((o) => o?.name?.toLowerCase()?.includes(currentContainer?.toLowerCase()))
              ?.sort((a, b) => (checkStatus(a) ?? 0) - (checkStatus(b) ?? 0))
              .map((a) => ({
                name: a?.name,
                status: (
                  <span
                    className={`inline-flex items-center rounded-full bg-${
                      status[checkStatus(a)]?.color
                    }-100 px-2.5 py-0.5 text-xs font-medium text-${
                      status[checkStatus(a)]?.color
                    }-800`}>
                    {status[checkStatus(a)]?.name}
                  </span>
                ),
                medicine: <span className="whitespace-normal">{showMedicines(a)}</span>,
                peremption: <span className="whitespace-normal">{showExpiryDates(a)}</span>,
                duplicate: (
                  <a
                    href="#"
                    className="text-indigo-600 hover:text-indigo-900 text-sm font-medium"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setContainerToDuplicate(a);
                      setOpenDuplicateModal(true);
                    }}>
                    Dupliquer
                  </a>
                )
              }))}
            labels={['Contenant', 'Statut', 'Médicament', 'Péremption', '']}
            onClick={(index) =>
              navigate('/containers-edit', {
                state: {
                  ...location.state,
                  containerId: containers
                    ?.filter((o) =>
                      o?.name?.toLowerCase()?.includes(currentContainer?.toLowerCase())
                    )
                    ?.sort((a, b) => (checkStatus(a) ?? 0) - (checkStatus(b) ?? 0))[index].id
                }
              })
            }
            parentClassName="sm:col-span-4"
          />
        </Section>
      </div>
      <Modal isOpen={openModal} setIsOpen={setOpenModal}>
        <Form
          mutation={createContainersMutation}
          setIsError={setIsError}
          setIsLoading={setIsLoading}
          callback={() => {
            queryClient.invalidateQueries('containers');
            setOpenModal(false);
          }}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-0 text-left">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Créer un contenant
              </Dialog.Title>
              {isError && (
                <Alert color="red" className="mb-4 mt-2">
                  Impossible de créer un contenant.
                  <br />
                  Assurez-vous d&apos;être connecté à internet
                </Alert>
              )}
              <InputText className="mt-3" name="name" label="Nom *" required={true} />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-between">
            <Button
              type="button"
              onClick={() => setOpenModal(false)}
              color="white"
              className="border border-gray-300 text-gray-700 hover:bg-gray-50 bg-white">
              Annuler
            </Button>
            <Button
              type="submit"
              color="green"
              onClick={() => {}}
              isLoading={isLoading}
              isWrong={isError}>
              Créer
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal isOpen={openDuplicateModal} setIsOpen={setOpenDuplicateModal}>
        <div className="px-4 py-3 sm:px-6 sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Dupliquer cet élément ?
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Êtes-vous sur de vouloir dupliquer ce contenant ?
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-between">
          <Button
            type="button"
            onClick={() => setOpenDuplicateModal(false)}
            color="white"
            className="border border-gray-300 text-gray-700 hover:bg-gray-50 bg-white">
            Annuler
          </Button>
          <Button
            color="green"
            onClick={() => duplicateContainer()}
            isLoading={isLoading}
            isWrong={isError}>
            Dupliquer
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Container;
