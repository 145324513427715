import { useState, useEffect } from 'react';
import { Notification } from '@components';
import { useIsOnline } from '@hooks';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const NetworkNotification = () => {
  const isOnline = useIsOnline();

  const [showNotification, setShowNotification] = useState(false);
  const [previousStatusValue, setPreviousStatusValue] = useState(true);

  useEffect(() => {
    if (previousStatusValue != isOnline) {
      setShowNotification(true);
      setPreviousStatusValue(isOnline);
    }
  }, [isOnline]);

  if (isOnline)
    return (
      <Notification show={showNotification} setShow={setShowNotification}>
        <p className="text-sm font-medium text-gray-900">Réseau disponible</p>
        <p className="mt-1 text-sm text-gray-500">
          L&apos;application va pouvoir faire la synchronisation avec le réseau.
        </p>
      </Notification>
    );

  return (
    <Notification
      show={showNotification}
      setShow={setShowNotification}
      icon={ExclamationTriangleIcon}
      iconClassName="text-orange-400">
      <p className="text-sm font-medium text-gray-900">Hors ligne</p>
      <p className="mt-1 text-sm text-gray-500">
        Vous venez de passer hors ligne, l&apos;application fonctionne en mode dégradé. Veuillez
        repasser en ligne pour synchroniser vos modifications.
      </p>
    </Notification>
  );
};

export default NetworkNotification;
