import { useState } from 'react';
import { Button, Modal, InputText, Alert, Form, Table, DropDown } from '@components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { usePersistMutation } from '@hooks';

import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import { Navigation, SubNavigation, Section, NetworkNotification } from '@layouts';
import queries from '@queries';
import uuid from 'react-uuid';

function Articles() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [currentArticle, setCurrentArticle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const createArticlesMutation = usePersistMutation(
    ['articles'],
    (data) => queries.post('/api/articles', data),
    () => setOpenModal(false),
    (variables) => ({ ...variables[0], id: uuid() }),
    false
  );

  const { isLoading: isLoadingArticles, data: dataArticles } = useQuery(['articles'], () =>
    queries.getAll('/api/articles')
  );

  const articles = (!isLoadingArticles && dataArticles?.data) || [];

  return (
    <div className="h-full min-h-screen flex flex-col bg-gray-100">
      <Navigation />
      <SubNavigation active="Articles" />
      <NetworkNotification />
      <div className="space-y-6 px-4 sm:px-6 lg:px-8 py-6">
        <Section title="Rechercher un article">
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div parentClassName="sm:col-span-4" className="grid grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <InputText
                value={currentArticle}
                onChange={(e) => setCurrentArticle(e.target.value)}
                placeholder="Filtrez le tableau grâce à un nom d'article ..."
              />
            </div>
            <div className="col-span-4 sm:col-span-1 flex justify-end items-end">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}>
                + Nouveau article
              </Button>
            </div>
          </div>
        </Section>
        <Section title="Listes des articles">
          <Table
            className="mt-0"
            data={articles
              ?.filter(
                (o) =>
                  o?.name?.toLowerCase()?.includes(currentArticle?.toLowerCase()) ||
                  o?.policy?.toString().toLowerCase()?.includes(currentArticle?.toLowerCase())
              )
              .map((a) => ({
                name: a.name,
                policy: a.policy + ' mois'
              }))}
            labels={['Nom', 'Politique Expiration']}
            onClick={(index) =>
              navigate('/articles-edit', {
                state: {
                  ...location.state,
                  articleId: articles?.filter(
                    (o) =>
                      o?.name?.toLowerCase()?.includes(currentArticle?.toLowerCase()) ||
                      o?.policy?.toString().toLowerCase()?.includes(currentArticle?.toLowerCase())
                  )[index].id
                }
              })
            }
            parentClassName="sm:col-span-4"
          />
        </Section>
      </div>
      <Modal isOpen={openModal} setIsOpen={setOpenModal}>
        <Form
          mutation={createArticlesMutation}
          setIsError={setIsError}
          setIsLoading={setIsLoading}
          callback={() => {
            queryClient.invalidateQueries('articles');
            setOpenModal(false);
          }}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-0 text-left">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Créer un article
              </Dialog.Title>
              {isError && (
                <Alert color="red" className="mb-4 mt-2">
                  Impossible de créer un article.
                  <br />
                  Assurez-vous d&apos;être connecté à internet
                </Alert>
              )}
              <InputText className="mt-3" name="name" label="Nom *" required={true} />
              <DropDown
                className="w-full mt-3"
                label="Politique Expiration"
                name="policy"
                data={[
                  { value: 1, label: '1 mois' },
                  { value: 2, label: '2 mois' },
                  { value: 3, label: '3 mois' },
                  { value: 4, label: '4 mois' },
                  { value: 5, label: '5 mois' },
                  { value: 6, label: '6 mois' }
                ]}
                defaultValue={2}
                required={true}
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-between">
            <Button
              type="button"
              onClick={() => setOpenModal(false)}
              color="white"
              className="border border-gray-300 text-gray-700 hover:bg-gray-50 bg-white">
              Annuler
            </Button>
            <Button
              type="submit"
              color="green"
              onClick={() => {}}
              isLoading={isLoading}
              isWrong={isError}>
              Créer
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default Articles;
