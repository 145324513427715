import baseUrl from '@root/config';
import axios from 'axios';

const configToken = {
  headers: {
    ...(localStorage.getItem('token') && {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    })
  }
};

const deleteAllFiles = async (deleteIds) =>
  await Promise.all(
    deleteIds?.map(
      async (id) => await axios.delete(`${baseUrl}/api/upload/files/${id}`, configToken)
    )
  );

const uploadNewFiles = async (orginalFormData, filesKeyArray) =>
  await Promise.all(
    filesKeyArray
      ?.filter((key) => orginalFormData?.getAll(key)?.some((file) => file?.size > 0)) // Filter only the non empty files
      ?.map(async (key) => {
        const formData = new FormData();

        // Create a formData that matches the /api/upload standard
        for (let file of orginalFormData.getAll(key)) {
          formData.append('files', file);
        }

        return [
          key?.split('.')?.at(-1), // key
          (await axios.post(`${baseUrl}/api/upload`, formData, configToken))?.data?.map(
            (o) => o?.id
          ) // value
        ];
      })
  );

function Form(props) {
  const {
    mutation = () => {},
    setIsLoading = () => {},
    setIsError = () => {},
    children = null,
    callback = () => {},
    ...rest
  } = props;

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    try {
      const orginalFormData = new FormData(e.target);
      const inputObject = Object.fromEntries(orginalFormData);

      // Get only the files
      const filesKeyArray = Object.keys(inputObject)?.filter((label) => label.includes('files'));
      const deletedFilesKeyArray = Object.keys(inputObject)?.filter((label) =>
        label.includes('deleted')
      );

      // Delete old files / removed files
      const deletedFilesId = deletedFilesKeyArray?.map((key) => inputObject?.[key]);
      await deleteAllFiles(deletedFilesId);

      // Upload new files
      const filesEntry = await uploadNewFiles(orginalFormData, filesKeyArray);

      // Get only the multiple
      const multipleKeysArray = Object.keys(inputObject)?.filter((label) =>
        label.includes('multiple')
      );

      // Parse multiple input values (if name contains 'multiple' )
      for (let key of multipleKeysArray)
        if (orginalFormData.getAll(key).length === 1 && orginalFormData.getAll(key).at(0) === '')
          inputObject[key?.split('multiple.')?.at(-1)] = [];
        else inputObject[key?.split('multiple.')?.at(-1)] = orginalFormData.getAll(key);

      // Remove the files and multiple key
      for (let key of filesKeyArray) delete inputObject[key];
      for (let key of multipleKeysArray) delete inputObject[key];
      for (let key of deletedFilesKeyArray) delete inputObject[key];

      // Append files to object
      const data = {
        ...inputObject,
        ...Object.fromEntries(filesEntry)
      };

      callback(await mutation.mutateAsync([data]));
    } catch (e) {
      setIsError(true);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={submit} {...rest}>
      {children}
    </form>
  );
}
export default Form;
