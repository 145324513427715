import { Header } from '@components';
import logo from '@assets/logo.svg';
import { useQuery } from '@tanstack/react-query';
import queries from '@queries';

const Navigation = () => {
  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), { retry: false });

  return (
    <Header
      logo={logo}
      classNameLogo={'p-2'}
      userActions={[
        {
          title: 'Déconnexion',
          fnc: () => {
            localStorage.removeItem('token');
            window.location.replace('/');
          }
        }
      ]}
      avatar={me?.data?.username
        ?.split(' ')
        ?.map((o) => o?.[0]?.toUpperCase())
        ?.join('')}
    />
  );
};

export default Navigation;
