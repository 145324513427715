import { InputText, Form, Alert, DropDown } from '@components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Navigation,
  SubNavigation,
  Section,
  NetworkNotification,
  ConfirmationModal,
  SectionButtons
} from '@layouts';
import queries from '@queries';
import { usePersistMutation } from '@hooks';

function EditMedicine() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMedicineMutation = usePersistMutation(
    ['containers'],
    (data) => {
      return queries.delete('/api/medicines', data);
    },
    () => {
      setOpenModalDeleteConfirmation(false);
      navigate(-1);
    },
    () => {
      return {
        ...dataContainers?.data?.filter((c) => c.id === containerId)[0],
        medicines: dataContainers?.data
          ?.filter((c) => c.id === containerId)[0]
          ?.medicines.filter((m) => m.id !== medicineId)
      };
    },
    'isDelete'
  );

  const { medicineId, containerId } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModalDeleteConfirmation, setOpenModalDeleteConfirmation] = useState(false);

  const { isLoading: isLoadingContainer, data: dataContainers } = useQuery(['containers'], () =>
    queries.getAll('/api/containers', { populate: ['medicines', 'medicines.article'] })
  );

  const { data: articles, isLoading: isLoadingArticles } = useQuery(['articles'], () =>
    queries.getAll('/api/articles')
  );

  const updateMedicineMutation = usePersistMutation(
    ['containers'],
    (data) => queries.put('/api/medicines', data),
    () => queryClient.invalidateQueries('containers'),
    (variables) => {
      return {
        ...dataContainers?.data?.filter((c) => c.id === containerId)[0],
        medicines: dataContainers?.data
          ?.filter((c) => c.id === containerId)[0]
          ?.medicines.map((m) => {
            if (m.id === medicineId) {
              return {
                ...m,
                expiryDate: variables[0].expiryDate,
                article: articles?.data?.filter((a) => a.id === parseInt(variables[0].article))[0]
              };
            } else return m;
          })
      };
    },
    true
  );

  if (isLoadingArticles || isLoadingContainer) return <></>;

  return (
    <div className="h-full min-h-screen flex flex-col bg-gray-100">
      <Navigation />
      <SubNavigation active="Contenant" state={location.state} />
      <NetworkNotification />
      <ConfirmationModal
        id={medicineId}
        isOpen={openModalDeleteConfirmation}
        setIsOpen={setOpenModalDeleteConfirmation}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isError={isError}
        setIsError={setIsError}
        deleteMutation={deleteMedicineMutation}
        invalidateQueries={['medicine', medicineId]}
      />

      <Form
        mutation={updateMedicineMutation}
        setIsError={setIsError}
        setIsLoading={setIsLoading}
        callback={() => {
          queryClient.invalidateQueries('medicine');
        }}>
        <div className="space-y-6 px-4 sm:px-6 lg:px-8">
          {isError && (
            <Alert color="red" className="mb-4 shadow-sm mt-4">
              Impossible de modifier le medicament.
              <br />
              Assurez-vous que les informations soient correctement renseignées.
            </Alert>
          )}

          <input
            name="id"
            type="hidden"
            value={
              dataContainers?.data
                ?.filter((c) => c.id === containerId)[0]
                ?.medicines?.filter((m) => m.id === medicineId)[0]?.id
            }
          />
          <Section
            title="Medicament"
            button={
              <SectionButtons
                queryKey={['medicine', 'container']}
                setIsOpen={setOpenModalDeleteConfirmation}
                isError={isError}
                isLoading={isLoading}
              />
            }>
            <DropDown
              className="w-full"
              label="Type"
              name="article"
              defaultValue={
                dataContainers?.data
                  ?.filter((c) => c.id === containerId)[0]
                  ?.medicines?.filter((m) => m.id === medicineId)[0]?.article?.id
              }
              data={articles?.data?.map((a) => ({
                label: a?.name,
                value: a?.id
              }))}
              required={true}
            />

            <InputText
              label="Date de péremption"
              type="date"
              name="expiryDate"
              classNameInput={'rounded'}
              defaultValue={
                dataContainers?.data
                  ?.filter((c) => c.id === containerId)[0]
                  ?.medicines?.filter((m) => m.id === medicineId)[0]?.expiryDate
              }
              required={true}
            />
          </Section>
        </div>
      </Form>
    </div>
  );
}

export default EditMedicine;
