import { TabNavigator } from '@components';

const tabs = [
  { name: 'Contenants', href: '/' },
  { name: 'Articles', href: '/articles' }
];

const SubNavigation = (props) => {
  const { active = '', state = {} } = props;

  return (
    <TabNavigator
      tabs={tabs.map((o) => ({
        ...o,
        active: active === o?.name,
        state
      }))}
    />
  );
};

export default SubNavigation;
