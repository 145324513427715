import React from 'react';

const Section = (props) => {
  const {
    title = '',
    subTitle = '',
    header = null,
    button = null,
    headerClassName,
    children
  } = props;

  return (
    <div className="shadow">
      <div className="bg-white py-6 px-4 sm:p-6">
        <div className="flex flex-row justify-between">
          <div>
            {title.length > 0 && (
              <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
            )}
            {subTitle.length > 0 && <p className="mt-1 text-sm text-gray-500">{subTitle}</p>}
          </div>
          {header && <div className={headerClassName}>{header}</div>}
        </div>

        <div className="mt-6 grid grid-cols-4 gap-6">
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className={`col-span-4 sm:col-span-2 ${child?.props?.parentClassName}`}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {button && <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">{button}</div>}
    </div>
  );
};

export default Section;
