import { useState } from 'react';
import logo from '@assets/logo.svg';
import { loginUser } from '@queries/User';
import { InputText, Alert, Loading, Button, Form } from '@components';
import loadingSvg from '@assets/loading.svg';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import queries from '@queries';
import { NetworkNotification } from '@layouts';

function Login({ children, isAdmin = false }) {
  const queryClient = useQueryClient();
  const me = useQuery(['me'], () => queries.getAll('/api/users/me'), {
    retry: false
  });
  const loginMutation = useMutation((data) => loginUser(data));

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return me?.isLoading ? (
    <>
      <Loading src={loadingSvg} />
    </>
  ) : isAdmin ? (
    me.data.isAdmin && me?.isSuccess ? (
      children
    ) : (
      <Loading src={loadingSvg} />
    )
  ) : me?.isSuccess ? (
    children
  ) : (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div className="px-4 py-5 sm:px-6 flex justify-center">
          <img src={logo} />
        </div>
        <NetworkNotification />
        <Form
          mutation={loginMutation}
          callback={(r) => {
            localStorage.setItem('token', r?.data?.jwt);
            queryClient.invalidateQueries('me');
            window.location.reload();
          }}
          raw={true}
          setIsError={setIsError}
          setIsLoading={setIsLoading}>
          <div className="px-4 py-5 sm:p-6">
            {isError && (
              <Alert color="red" className="mb-4">
                Le nom d&apos;utilisateur et le mot <br />
                de passe ne semble pas correspondre.
              </Alert>
            )}
            <InputText type="text" name="identifier" label="Utilisateur" />
            <InputText className="mt-4" type="password" name="password" label="Mot de passe" />
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button type="submit" isWrong={isError} isLoading={isLoading} onClick={() => {}}>
              Se Connecter
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
