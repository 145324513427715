import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { Avatar } from '@components';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header(props) {
  const {
    avatar = '',
    links = [],
    selected = 0,
    userActions = [],
    logo = '',
    classNameLogo = '',
    title = '',
    color = 'blue'
  } = props;

  return (
    <Disclosure as="nav" className="bg-white shadow z-10">
      {() => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex items-center">
                  <Link to="/" className="h-full flex justify-center items-center">
                    <img src={logo} className={'h-full ' + classNameLogo} />
                    <h2 className="ml-3 text-lg font-medium">{title}</h2>
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {links?.map((elem, index) => (
                    <a
                      id={index}
                      key={index}
                      onClick={(e) => elem.fnc(e)}
                      className={
                        index === selected
                          ? `border-${color}-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                      }>
                      {elem?.title}
                    </a>
                  ))}
                </div>
              </div>
              <div className="ml-6 flex items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button
                      className={`bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500`}>
                      <span className="sr-only">Open user menu</span>
                      <Avatar>{avatar}</Avatar>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userActions?.map((elem, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <a
                              href="#"
                              key={index}
                              onClick={(e) => elem?.fnc(e)}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}>
                              {elem?.title}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
export default Header;
