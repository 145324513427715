import { Modal, Form, Button } from '@components';
import { useQueryClient } from '@tanstack/react-query';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

const ConfirmationModal = (props) => {
  const {
    id = null,
    isOpen = false,
    setIsOpen = false,
    deleteMutation = () => {},
    isError = false,
    setIsError = () => {},
    isLoading = false,
    setIsLoading = () => {},
    callback = () => {},
    invalidateQuery = ''
  } = props;

  const queryClient = useQueryClient();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form
        mutation={deleteMutation}
        setIsError={setIsError}
        setIsLoading={setIsLoading}
        callback={() => {
          callback();
          queryClient.invalidateQueries(invalidateQuery);
        }}>
        <input name="id" type="hidden" value={id} />
        <div className="px-4 py-3 sm:px-6 sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Supprimer cet élément ?
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Êtes-vous sur de vouloir supprimer cet élément ? Cette suppression est irréversible.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 flex justify-between sm:px-6">
          <Button
            type="button"
            onClick={() => setIsOpen(false)}
            color="white"
            className="border border-gray-300 text-gray-700 hover:bg-gray-50 bg-white">
            Annuler
          </Button>
          <Button
            type="submit"
            color="red"
            onClick={() => {}}
            isLoading={isLoading}
            isWrong={isError}>
            Supprimer
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ConfirmationModal;
