function Toggle(props) {
  const { name = '', className = '', classNameToggle = '', form = undefined, ...rest } = props;

  return (
    <div className={'relative ' + className}>
      <label htmlFor={name} className="inline-flex relative items-center cursor-pointer">
        <input name={name} type="hidden" value={false} form={form} />
        <input
          type="checkbox"
          value={true}
          name={name}
          id={name}
          className="sr-only peer"
          form={form}
          {...rest}
        />
        <div
          className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-cyan-600 ${classNameToggle}`}></div>
      </label>
    </div>
  );
}

export default Toggle;
