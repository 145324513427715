export default {
  DEV: 'http://localhost:1337',
  STAGING: 'https://redmonitor-staging-back.herokuapp.com',
  PRODUCTION: 'https://redmonitor-prod-back.herokuapp.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];

export const status = [
  { name: 'Expiré', color: 'red' },
  { name: 'A vérifier', color: 'yellow' },
  { name: 'Bon', color: 'green' }
];
