import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Articles, Login, ArticlesEdit, Container, ContainerEdit, EditMedicine } from '@pages';
import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const persister = createSyncStoragePersister({
  storage: window.localStorage
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        // resume mutations after initial restore from localStorage was successful
        queryClient.resumePausedMutations();
      }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/articles"
            element={
              <Login>
                <Articles />
              </Login>
            }></Route>
          <Route
            path="/articles-edit"
            element={
              <Login>
                <ArticlesEdit />
              </Login>
            }></Route>
          <Route
            path="/"
            element={
              <Login>
                <Container />
              </Login>
            }></Route>
          <Route
            path="/containers-edit"
            element={
              <Login>
                <ContainerEdit />
              </Login>
            }></Route>
          <Route
            path="/medicines-edit"
            element={
              <Login>
                <EditMedicine />
              </Login>
            }></Route>
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
