import { useState } from 'react';

function DropDown(props) {
  const {
    label = '',
    className = '',
    classNameSelect = '',
    name = '',
    defaultValue = [],
    selectName = '',
    data = [],
    value: valueControlled,
    onChange: setValueControlled,
    isControlled = false,
    ...rest
  } = props;

  const [value, setValue] = useState(defaultValue);

  return (
    <div className={'relative ' + className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        className={`mt-1 w-full form-select appearance-none block grow px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ${classNameSelect}`}
        aria-label="Default select example"
        value={isControlled ? valueControlled : value}
        onChange={isControlled ? setValueControlled : (e) => setValue(e.target.value)}
        name={name}
        {...rest}>
        <option hidden>{selectName}</option>
        {data.map((e, index) => (
          <option name={name} key={index} value={e.value}>
            {e.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDown;
