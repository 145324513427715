import { Button } from '@components';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const SectionButtons = (props) => {
  const { queryKey = 'article', setIsOpen = false, isError = false, isLoading = false } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="flex justify-between w-full">
      <Button type="button" onClick={() => setIsOpen(true)} color="red">
        Supprimer
      </Button>
      <Button
        isLoading={isLoading}
        isWrong={isError}
        type="submit"
        onClick={() => {
          queryClient.invalidateQueries(queryKey);
          navigate(-1);
        }}>
        Enregistrer
      </Button>
    </div>
  );
};

export default SectionButtons;
