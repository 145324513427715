import { InputText, Form, Alert, DropDown } from '@components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Navigation, SubNavigation, Section, ConfirmationModal, SectionButtons } from '@layouts';
import queries from '@queries';
import { NetworkNotification } from '@layouts';
import { usePersistMutation } from '@hooks';

function ArticlesEdit() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { articleId = 1 } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModalDeleteConfirmation, setOpenModalDeleteConfirmation] = useState(false);

  const deleteArticleMutation = usePersistMutation(
    ['articles'],
    (data) => {
      return queries.delete('/api/articles', data);
    },
    () => {
      setOpenModalDeleteConfirmation(false);
      navigate(-1);
    },
    () => articleId,
    'isDelete'
  );

  const { data: dataArticles, isLoading: dataArticlesLoading } = useQuery(['articles'], () =>
    queries.getAll('/api/articles', { populate: ['medicines', 'medicines.article'] })
  );

  const updateArticleMutation = usePersistMutation(
    ['articles'],
    (data) => queries.put('/api/articles', data),
    () => queryClient.invalidateQueries('articles'),
    (variables) => {
      return {
        ...dataArticles?.data?.filter((c) => c.id === articleId)[0],
        name: variables[0].name
      };
    },
    true
  );

  if (dataArticlesLoading) return <></>;

  return (
    <div className="h-full min-h-screen flex flex-col bg-gray-100">
      <Navigation />
      <SubNavigation active="Articles" state={location.state} />
      <NetworkNotification />
      <ConfirmationModal
        id={articleId}
        isOpen={openModalDeleteConfirmation}
        setIsOpen={setOpenModalDeleteConfirmation}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isError={isError}
        setIsError={setIsError}
        deleteMutation={deleteArticleMutation}
        invalidateQueries={['article', articleId]}
      />
      <Form
        mutation={updateArticleMutation}
        setIsError={setIsError}
        setIsLoading={setIsLoading}
        callback={() => {
          queryClient.invalidateQueries('article');
        }}>
        <div className="space-y-6 px-4 sm:px-6 lg:px-8">
          {isError && (
            <Alert color="red" className="mb-4 shadow-sm mt-4">
              Impossible de modifier l&apos;article.
              <br />
              Assurez-vous que les informations soient correctement renseignées.
            </Alert>
          )}

          <input
            name="id"
            type="hidden"
            value={dataArticles?.data?.filter((c) => c.id === articleId)[0]?.id}
          />
          <Section
            title="Article"
            button={
              <SectionButtons
                queryKey="article"
                setIsOpen={setOpenModalDeleteConfirmation}
                isError={isError}
                isLoading={isLoading}
              />
            }>
            <InputText
              label="Nom"
              name="name"
              classNameInput={'rounded'}
              defaultValue={dataArticles?.data?.filter((c) => c.id === articleId)[0]?.name}
              required={true}
            />
            {dataArticles?.data?.filter((c) => c.id === articleId)[0]?.policy && (
              <DropDown
                className="w-full"
                label="Politique Expiration"
                name="policy"
                data={[
                  { value: 1, label: '1 mois' },
                  { value: 2, label: '2 mois' },
                  { value: 3, label: '3 mois' },
                  { value: 4, label: '4 mois' },
                  { value: 5, label: '5 mois' },
                  { value: 6, label: '6 mois' }
                ]}
                defaultValue={dataArticles?.data?.filter((c) => c.id === articleId)[0]?.policy}
                required={true}
              />
            )}
          </Section>
        </div>
      </Form>
    </div>
  );
}

export default ArticlesEdit;
