import { useMutation, useQueryClient } from '@tanstack/react-query';

const usePersistMutation = (key, fnc, callback, optimisticValueFnc, flag) => {
  const queryClient = useQueryClient();

  return useMutation(fnc, {
    onMutate: async (variables) => {
      // Cancel current queries for the values list
      await queryClient.cancelQueries(key);
      let optimisticValue;

      // Create optimistic value
      optimisticValue = optimisticValueFnc(variables);

      // Add optimistic value
      queryClient.setQueryData(key, (old) => {
        const temp = structuredClone(old);
        if (flag === 'isUpdate') {
          temp.data = old.data.map((o) => {
            if (o.id === optimisticValue.id) {
              return optimisticValue;
            } else return o;
          });
        } else if (flag === 'isCreate') temp.data = [...old.data, optimisticValue];
        else {
          temp.data = [
            ...old.data
              .map((o) => {
                if (o.id === optimisticValue.id) {
                  return optimisticValue;
                } else return o;
              })
              .filter((o) => o.id !== optimisticValue)
          ];
        }
        return temp;
      });

      callback(optimisticValue);
      // Return context with the optimistic value
      return { optimisticValue };
    },
    onSuccess: (result, variables, context) => {
      // Replace optimistic value in the values list with the result
      queryClient.setQueryData(key, (old) => {
        const temp = structuredClone(old);
        temp.data = old.data.map((value) =>
          value.id === context.optimisticValue.id ? result?.[0]?.data?.data : value
        );

        return temp;
      });
    },
    onError: (error, variables, context) => {
      // Remove optimistic value from the values list
      queryClient.setQueryData(key, (old) => {
        const temp = structuredClone(old);
        temp.data = old.data.filter((value) => value.id !== context.optimisticValue.id);
        return temp;
      });
    }
  });
};

export default usePersistMutation;
