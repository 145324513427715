const capitalize = (str) =>
  str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');

const Table = (props) => {
  const {
    data = [],
    labels = Object.keys(data?.[0] || []).map((o) => capitalize(o)),
    onClick = () => {},
    className = '',
    hideEdit = false
  } = props;

  return (
    <div className={'mt-8 flex flex-col ' + className}>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              {labels.length > 0 && (
                <thead className="bg-gray-50">
                  <tr>
                    {labels.map((o, index) => (
                      <th
                        key={`label-${index}`}
                        scope="col"
                        className={
                          index == 0
                            ? 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                            : 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        }>
                        {o}
                      </th>
                    ))}
                    {!hideEdit && (
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Modifier</span>
                      </th>
                    )}
                  </tr>
                </thead>
              )}
              <tbody className="bg-white">
                {data.map((o, index) => (
                  <tr key={`row-${index}`} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    {Object.keys(o).map((key, keyIndex) => (
                      <td
                        key={`row-${index}-${keyIndex}`}
                        className={
                          keyIndex == 0
                            ? 'whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 text-sm sm:pl-6'
                            : 'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                        }>
                        {o?.[key]}
                      </td>
                    ))}
                    {!hideEdit && (
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick(index);
                          }}>
                          Modifier
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
